<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">
            <b-row>
                <b-col cols="12">
                    <zw-switch-group v-model="enabled"
                                     name="enabled"
                                     :label-prefix="'settings.integrations.label.'"
                    ></zw-switch-group>
                </b-col>
                <template v-if="enabled==true">
                    <b-col cols="12">
                        <zw-input-group v-model="form.app_id"
                                        :label-prefix="labelPrefix"
                                        name="app_id"
                                        size="sm"
                        />
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group v-model="form.app_secret"
                                        :label-prefix="labelPrefix"
                                        name="app_id"
                                        size="sm"
                                        type="password"
                        />
                    </b-col>
                </template>
                <b-col cols="12">
                    <b-button @click="save" block variant="primary">{{ $t('common.button.save') }}</b-button>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'integration-facebook',
    mixins: [commonSave],
    props: {
        'form': null,
        'afterSave': null,
        'name': null,
        'enabled': null
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.integrations.facebook_marketing.label.',
        }
    },
    methods: {
        shown() {
            this.loading = false
        },
        save() {
            this.loading = true
            this.$store.dispatch('Integrations/saveModule', {
                enabled: this.enabled,
                name: this.name,
                configuration: this.form
            })
                .then((response) => {
                    this.afterSave()
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    mounted() {
        this.shown()
    }
}
</script>